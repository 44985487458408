import { detailTypes } from "../../ActionTypes/detailTypes";
import {
  FetchDetailsFailure,
  FetchDetailsFailurePayload,
  FetchDetailsRequest,
  FetchDetailsSuccess,
  FetchDetailsSuccessPayload,
} from "../../types/type";

export const fetchDetailRequest = (numb:any): FetchDetailsRequest => ({
  type: detailTypes.FETCH_DETAIL_REQUEST,
  numb,
});

export const fetchDetailSuccess = (
  payload: FetchDetailsSuccessPayload,
): FetchDetailsSuccess => ({
  type: detailTypes.FETCH_DETAIL_SUCCESS,
  payload
});

export const fetchDetailFailure = (
  payload: FetchDetailsFailurePayload
): FetchDetailsFailure => ({
  type: detailTypes.FETCH_DETAIL_FAILURE,
  payload
});
