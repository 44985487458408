import { listTypes } from "../../ActionTypes/listTypes";
import {
  FetchListFailure,
  FetchListFailurePayload,
  FetchListRequest,
  FetchListSuccess,
  FetchListSuccessPayload
} from "../../types/type";

export const fetchListRequest = (numb:any): FetchListRequest => ({
  type: listTypes.FETCH_LIST_REQUEST,
  numb
});

export const fetchListSuccess = (
  payload: FetchListSuccessPayload,
): FetchListSuccess => ({
  type: listTypes.FETCH_LIST_SUCCESS,
  payload
});

export const fetchListFailure = (
  payload: FetchListFailurePayload
): FetchListFailure => ({
  type: listTypes.FETCH_LIST_FAILURE,
  payload
});
