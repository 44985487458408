import React from "react";
import { Col, Row } from "react-bootstrap";
import './footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
function Footer() {
  return (
    <footer>
       <Row className="drow">
        <Col xs={12} sm={6} xl={4} className="foot1">
              <a href="https://www.facebook.com/"><FontAwesomeIcon icon={faFacebook} className="pl" title="Facebook" /> </a>
              <a href="https://www.instagram.com/"><FontAwesomeIcon icon={faInstagram} className="pl" title="Instagram" /> </a>
              <a href="https://twitter.com/?lang=en"><FontAwesomeIcon icon={faTwitter} className="pl" title="Twitter" /></a>
        </Col>
        <Col xs={12} sm={6} xl={4} className="foot2">
          <p>Copyright © {new Date().getFullYear()} <a href="https://babeka.mn" target="_blank" rel="noreferrer">BABEKA</a> бүх эрх хуулиар хамгаалагдсан.</p>
        </Col>
        <Col xs={12}  xl={4} className="foot3">
            <a href="tel:976 89063443" className="pr"><FontAwesomeIcon icon={faPhone} /> +976 8906-3443</a>
            <a href="mailto:contact@unisub-tech.com" className="pr" ><FontAwesomeIcon icon={faEnvelope}/> contact@unisub-tech.com</a>  
        </Col>
      </Row>
      <div>
        
      </div>
    </footer>
  );
}

export default Footer;