import { rateTypes } from "../../ActionTypes/rateTypes";
import {
  FetchRateFailure,
  FetchRateFailurePayload,
  FetchRateRequest,
  FetchRateSuccess,
  FetchRateSuccessPayload
} from "../../types/type";

export const fetchRateRequest = (w: any): FetchRateRequest => ({
  type: rateTypes.FETCH_RATE_REQUEST,
  work: w
});

export const fetchRateSuccess = (
  payload: FetchRateSuccessPayload
): FetchRateSuccess => ({
  type: rateTypes.FETCH_RATE_SUCCESS,
  payload
});

export const fetchRateFailure = (
  payload: FetchRateFailurePayload
): FetchRateFailure => ({
  type: rateTypes.FETCH_RATE_FAILURE,
  payload
});
