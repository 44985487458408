import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

const Slider = (props:any) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: React.SetStateAction<number>, e: any) => {
    setIndex(selectedIndex);
  };

  var banners = props.data;
 
  return (
    <>
    {banners && banners !== undefined ? (
      <Carousel activeIndex={index} onSelect={handleSelect}>
        {banners.map((banner: any) => {
            return (
              <Carousel.Item key={banner.id}>
              <img
                className="d-block w-100"
                src= {"images/banner/" + banner.image}
                alt={banner.name}
              />
              <Carousel.Caption>
                {isMobile ? (
                  <></>
                ) : (
                  <h3>
                    {banner.type === 'book' ? (
                      <Link className='banner-link' to={`/detail/${banner.id}`}>{banner.name}</Link>
                    ) : (
                      <>{banner.name}</>
                    )}                    
                  </h3>
                )}
                {/* <p>{banner.short_desc}</p> */}
              </Carousel.Caption>
            </Carousel.Item>
            );
          })}
    </Carousel>
    ) : (
      <></>
    )}
    </>
  );
}

export default Slider;