import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faDroplet, faEnvelope, faHandScissors, faLocationDot, faPhone, faScissors, faMapMarkerAlt, faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Modal, Nav, Row } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import ListGroup from 'react-bootstrap/ListGroup';
import GoogleMapReact from "google-map-react";
import './detail.css';
import React, { useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { fetchDetailRequest } from '../../redax/actions/detailActions/detailActions';
import { RootState } from '../../redax/reducers/rootReducer';
import { fetchRateFailure, fetchRateRequest } from '../../redax/actions/rateActions/rateActions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MyVerticallyCenteredModal(props:any) {
  if(props.data != null) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="detail-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.data.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.type === 'service' ? (
            <h4 style={{textAlign: 'center', color: '#229b14'}}>Үнэ: {props.data.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]}₮</h4>
          ) : props.type === 'salbar' ? (
            <>
              <p>Утас: {props.data.phone}</p>
              <p>Хаяг: {props.data.address}</p>
            </>
          ) : props.type === 'worker' ? (
            <>
              {/* <p>Мэдээлэл: {props.data.descr}</p> */}
            </>
          ) : props.type === 'promotion' ? (
            <>
              <p style={{textAlign: 'center', color: '#229b14'}}>Дуусах хугацаа: {
               new Date(props.data.n_date).getUTCFullYear() + "-" +
               ("00" + (new Date(props.data.n_date).getUTCMonth() + 1)).slice(-2) + "-" +
               ("00" + new Date(props.data.n_date).getUTCDate()).slice(-2) + " " +
               ("00" + new Date(props.data.n_date).getUTCHours()).slice(-2) + ":" +
               ("00" + new Date(props.data.n_date).getUTCMinutes()).slice(-2) + ":" +
               ("00" + new Date(props.data.n_date).getUTCSeconds()).slice(-2)}</p>
            </>
          ) : (
            <></>
          )}
          <p>
            {props.data.descr}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Хаах</Button>
        </Modal.Footer>
      </Modal>
    );
  } else {
    return null;
  }
}

function Detail(props: any) {
  const [modalShow, setModalShow] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [type, setType] = React.useState(null);
  const defaultProps = {
    center: {
      lat: 47.9188,
      lng: 106.9176
    },
    zoom: 16
  };
  let defValue = 'about';

  const dispatch = useDispatch();
  const { detail, pending } = useSelector(
    (state: RootState) => state.detail
  );
  const { rate, pendingRate } = useSelector(
    (state: RootState) => state.rate
  );

  let numb: any;
  numb  = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);  

  // console.log(detail);
  const openModal = (dt: any, type: any) => {
    setModalShow(true);
    setData(dt);
    setType(type);
  };

  useEffect(() => {
    window.scroll(0, 0);
    // dispatch(fetchDetailRequest(numb));
    props.fetchDetailRequest(numb)
  }, []);

  if (window.location.search.substr(1).split("=")[1] !== undefined) {
    defValue = window.location.search.substr(1).split("=")[1];
  }

  const renderMarkers = (map: any, maps: any) => {
    if(detail.detail.location) {
      let lat1 = Number(detail.detail.location.split(",")[0]);
      let lng1 = Number(detail.detail.location.split(",")[1]);

      let marker = new maps.Marker({
        position: {
          lat: lat1,
          lng: lng1
        },
        map,
        title: detail.detail.name,
        icon: '/images/blue-marker.png',
      });
      map.panTo(marker.getPosition());
      map.setMapTypeId('satellite');
    }
  }

  const setRate = (worker: any, rate: any) => {
    // console.log(worker, rate);
    dispatch(fetchRateRequest({worker_id: worker, rate: rate}));
  }

  if (pendingRate) {
    if(rate.message === 'OK') {
      toast.success("АМЖИЛТТАЙ ҮНЭЛЛЭЭ.", {
        onClose: () => {
          dispatch(fetchRateFailure(rate));
        },
        autoClose: 3000
      });
    }
  }
  
  if (!props.detail) {
    return (<h1 className="heading">Loading!</h1>)
  } else {
    return (
      <div className="container-fluid">
        {pending ? (
          <>
            <Row className='m-0 p-0'>
              {detail.detail.main_image ? (
                <Col md={12} sm={12} xs={12} className="image-section" style={{backgroundImage: `url("../images/${detail.detail.main_image}")`,backgroundRepeat: 'no-repeat'}}></Col>
              ) : (
                <Col md={12} sm={12} xs={12} className="image-section" style={{backgroundImage: `url("../images/no-banner.png")`,backgroundRepeat: 'no-repeat'}}></Col>
              )}
              <Row className='m-0 p-0'>
                <Col className='m-0 p-0'>
                  <div className='pro'>
                    <div className='profile'>
                      <img src = {"../images/book/" + detail.detail.image} alt="user logo"></img>
                      <div className='texts'>
                        <p className='title-text'> {detail.detail.name}</p>
                        <p className='subtitle'> {detail.detail.cat_name} </p>
                    </div>
                    </div>
                    <div className='social text-center'>
                      <div className='d-flex justify-content-center'>
                        <div className='pr-3'>
                          <a href={`tel:${detail.detail.phone_number}`}><FontAwesomeIcon icon={faPhone} /> {detail.detail.phone_number}</a> <br />
                          <a href={`mailto:${detail.detail.email}`}><FontAwesomeIcon icon={faEnvelope}/> {detail.detail.email}</a> 
                        </div>
                        <div>
                          <a href={detail.detail.instagram} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram}/> {detail.detail.instagram}</a> <br />
                          <a href={detail.detail.facebook} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook}/> {detail.detail.facebook}</a> 
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Tab.Container id="list-group-tabs-example" defaultActiveKey={`#${defValue}`}>
                <Row className='mt p-0'>
                  <Col md={3} className='m-0 p-0 pb-3'>
                    <ListGroup className='st'>
                      <ListGroup.Item action href="#about">
                        Бидний тухай
                      </ListGroup.Item>
                      <ListGroup.Item action href="#service">
                        Үйлчилгээ
                      </ListGroup.Item>
                      <ListGroup.Item action href="#promotion">
                        Хөнгөлөлт урамшуулал
                      </ListGroup.Item>
                      <ListGroup.Item action href="#branch">
                        Салбар
                      </ListGroup.Item>
                      <ListGroup.Item action href="#worker">
                        Ажилчид
                      </ListGroup.Item>
                      <ListGroup.Item action href="#address">
                        Хаяг байршил
                      </ListGroup.Item>
                    </ListGroup>
                  </Col>
                  <Col md={9}  className='m-0 paddr'>
                    <Tab.Content>
                      <Tab.Pane eventKey="#service" id = "service">
                        <h3>
                          Үйлчилгээ
                        </h3>
                        {detail.services && detail.services !== undefined ? (
                          <div className="row m-0 p-0">
                            {detail.services.map((services: any) => {
                                return (
                                  <div className="col-lg-4 mt-4 col-md-6 col-sm-12  gallery-item" id={services.id}  onClick={() => openModal(services, 'service')} key = {services.id}>

                                  <img src={"../images/banner/" + services.image} className="gallery-image" alt=""></img>
                                  <div className="gallery-item-info">
        
                                    <ul>
                                      <li className="gallery-item-likes">
                                      <FontAwesomeIcon icon={faDroplet}/>  {services.name}</li>
                                      <li>
                                        {/* Үнэ: {services.price} */}
                                      </li> 
                                      <li>
                                          <p className='fs'>
                                            {/* {services.descr} */}
                                          </p>
                                      </li>
                                    </ul>
        
                                  </div>
        
                                </div>
                                );
                              })}
                        </div>
                        ) : (
                          <></>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="#branch" id = "sector">

                      <h3>
                          Салбар
                        </h3>

                        {detail.salbar && detail.salbar !== undefined ? (
                          <div className="row m-0 p-0">
                            {detail.salbar.map((salbar: any) => {
                                return (
                                  <div className="gallery-item col-lg-4 mt-4 col-md-6 col-sm-12"  onClick={() => openModal(salbar, 'salbar')} key = {salbar.id}>

                                  <img src={"../images/book/" + salbar.image} className="gallery-image" alt=""></img>
                                  <div className="gallery-item-info">
        
                                    <ul>
                                      <li className="gallery-item-likes">
                                      <FontAwesomeIcon icon={faMapMarkerAlt}/>  {salbar.name}</li>
                                      {/* <li>
                                        Утасны дугаар: {salbar.phone}
                                      </li> 
                                      <li>
                                          <p className='fs'>
                                            Хаяг: {salbar.address}
                                          </p>
                                      </li> */}
                                    </ul>
        
                                  </div>
        
                                </div>
                                );
                              })}
                        </div>
                        ) : (
                          <></>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="#worker" id = "employees">
                      <h3>
                          Ажилчид
                        </h3>
                        {detail.workers && detail.workers !== undefined ? (
                          <div className="row">
                            {detail.workers.map((workers: any, index: Number) => {
                                return (
                                  <div className="col-lg-3 col-6" key={workers.id}>
                                  <div className="our-team">
                                    <div onClick={(id) => openModal(workers, 'worker')}>
                                      <div className="pic">
                                            <img src={"../images/workers/" + workers.image} alt={workers.name}></img>
                                        </div>
                                        <h3 className="title">{workers.name}</h3>
                                        <span className="post">{workers.short_descr}</span>
                                    </div>
                                    <div className='d-flex'>
                                      <div className="rate">
                                          {Number(workers.rate) >= 4.5 ? (
                                            <input type="radio" id={workers.name + "5"} name={`rate-${index}`} value="5" defaultChecked={true} onClick={() => setRate(workers.id, 5)} />
                                          ) : (
                                            <input type="radio" id={workers.name + "5"} name={`rate-${index}`} value="5" onClick={() => setRate(workers.id, 5)} />
                                          )}
                                          <label htmlFor={workers.name + "5"} title="Үнэлгээ өгөх">5 stars</label>
                                          {Number(workers.rate) >= 3.5 && Number(workers.rate) < 4.5 ? (
                                            <input type="radio" id={workers.name + "4"} name={`rate-${index}`} value="4" defaultChecked={true} onClick={() => setRate(workers.id, 4)} />
                                          ) : (
                                            <input type="radio" id={workers.name + "4"} name={`rate-${index}`} value="4" onClick={() => setRate(workers.id, 4)} />
                                          )}
                                          <label htmlFor={workers.name + "4"} title="Үнэлгээ өгөх">4 stars</label>
                                          {Number(workers.rate) >= 2.5 && Number(workers.rate) < 3.5 ? (
                                            <input type="radio" id={workers.name + "3"} name={`rate-${index}`} value="3" defaultChecked={true} onClick={() => setRate(workers.id, 3)} />
                                          ) : (
                                            <input type="radio" id={workers.name + "3"} name={`rate-${index}`} value="3" onClick={() => setRate(workers.id, 3)} />
                                          )}
                                          <label htmlFor={workers.name + "3"} title="Үнэлгээ өгөх">3 stars</label>
                                          {Number(workers.rate) >= 1.5 && Number(workers.rate) < 2.5 ? (
                                            <input type="radio" id={workers.name + "2"} name={`rate-${index}`} value="2" defaultChecked={true} onClick={() => setRate(workers.id, 2)} />
                                          ) : (
                                            <input type="radio" id={workers.name + "2"} name={`rate-${index}`} value="2" onClick={() => setRate(workers.id, 2)} />
                                          )}
                                          <label htmlFor={workers.name + "2"} title="Үнэлгээ өгөх">2 stars</label>
                                          {Number(workers.rate) > 0 && Number(workers.rate) < 1.5 ? (
                                            <input type="radio" id={workers.name + "1"} name={`rate-${index}`} value="1" defaultChecked={true} onClick={() => setRate(workers.id, 1)} />
                                          ) : (
                                            <input type="radio" id={workers.name + "1"} name={`rate-${index}`} value="1" onClick={() => setRate(workers.id, 1)} />
                                          )}
                                          <label htmlFor={workers.name + "1"} title="Үнэлгээ өгөх">1 star</label>
                                        </div>
                                    </div>
                                  </div>
                              </div>
                                );
                              })}
                        </div>
                        ) : (
                          <></>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="#about">
                        <h3>
                          Бидний тухай
                        </h3>
                        <p>
                          {detail.detail.book_desc}
                        </p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="#promotion">
                        <h3>
                          Хөнгөлөлт урамшуулал
                        </h3>

                        {detail.promotions && detail.promotions !== undefined ? (
                          <div className="row m-0 p-0">
                            {detail.promotions.map((promotions: any) => {
                                return (
                                  <div className="gallery-item  col-lg-4 mt-4 col-md-6 col-sm-12" id = {promotions.id} onClick={() => openModal(promotions, 'promotion')} key = {promotions.id}>

                                  <img src={"../images/book/" + promotions.image} className="gallery-image" alt=""></img>
                                  <div className="gallery-item-info">
        
                                    <ul>
                                      <li className="gallery-item-likes">
                                      <FontAwesomeIcon icon={faCartPlus}/>  {promotions.name}</li>
                                      {/* <li>
                                        Утасны дугаар: {promotions.phone}
                                      </li> 
                                      <li>
                                          <p className='fs'>
                                            Хаяг: {promotions.address}
                                          </p>
                                      </li> */}
                                    </ul>
        
                                  </div>
        
                                </div>
                                );
                              })}
                        </div>
                        ) : (
                          <></>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="#address">
                        <h3>
                          Хаяг байршил
                        </h3>
                        <p className='fs'>
                          {detail.detail.address}
                        </p>
                        <div style={{ height: '400px', width: '100%', marginTop: '10px' }}>
                          <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyANrSZmh9Dzui1tXkC9H6R72Jv_03JxGcE" }}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}
                          >
                          </GoogleMapReact>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Row>
            <MyVerticallyCenteredModal
              show={modalShow}
              data={data}
              type={type}
              onHide={() => setModalShow(false)}
            />
            <ToastContainer position="bottom-right" />
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    detail: state.detail
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchDetailRequest: (numb: any) => dispatch(fetchDetailRequest(numb))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
